<template>
  <div class="ocr-container -scrollable text">
    <div class="vstack -fit">
      <div class="hstack">
        <section class="cropper-area">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="16 / 9"
            :src="imgSrc"
            alt="Source Image"
            :viewMode="2"
            preview=".preview"
            dragMode="crop"
            @ready="cropImage"
            @cropend="cropImage"
          >
          </vue-cropper>
        </section>
        <section class="preview-area">
          <div class="cropped-image">
            <img
              v-if="cropImg"
              :src="cropImg"
              alt="Cropped Image"
            />
            <div v-else class="crop-placeholder" />
          </div>
        </section>
      </div>
      <div class="hstack button-area">
        <sea-button @action="doOcr('plate')">{{ l.ocr.button_plate }}</sea-button>
        <sea-button @action="doOcr('vehicleLicense')">{{ l.ocr.button_vehicle }}</sea-button>
        <sea-button @action="doOcr('drivingLicense')">{{ l.ocr.button_license }}</sea-button>
      </div>
      <div><textarea readonly rows="10" cols="50">{{result}}</textarea></div>
    </div>
  </div>
</template>

<style lang="scss">
.ocr-container {

  .input {
    border: 1px solid gray;
    background: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
    color: #1e89f6;
    margin-right: 0.5rem;
  }
  
  .cropper-area {
    width: 70%;
  }
  
  .preview-area {
    width: 25%;
    margin-left: 1rem;
  }
  
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }
  
  .cropped-image img {
    max-width: 100%;
  }
  
  .button-area {
    padding: 1rem;
  }
  
  textarea {
    width: 50rem;
    height: 10rem;
    resize: none;
  }

}
</style>

<script>
import { messages } from "@/lib/emitter"
import { ocrTest } from "../lib/ocr"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import SeaButton from "../ui/sea-button"
import { Logger } from "../lib/logger"
const log = Logger("app:app-ocr")

export default {
  name: "app-ocr",
  components: { VueCropper, SeaButton },
  data() {
    return {
      imgSrc: "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAA4QAAAH0BAMAAACX3f7gAAAAElBMVEX/////+v///f//+////P///v+82+bFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACV0lEQVR4nO3ZsY6bMBwHYBPCjuO7Pai9PZQXSIbud1Xf/1lqYyJdUrp0gZO+bwGb/2DpJ4xtQgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGAThzH+yJdvMZ3vjbHPHbc+NDHG/qmC/RnjGK/hEFN8CWGYGx+nELp4Dk2apsu9c6lgdw45quE1NKd89x5qo9y1JdDLXPJQwe6UqNo0pzVeDmlu5Lsw5Bl0uJaKpbNWbDxaVuSXK79dYbzmxPqSZ27kmbTMo+F2LhVLZ63YcKT8wxJhSeyjb+aPYAjHWHIL4zS91YruXrHxaFnxKcLmtTRC+eDFecrMC9K8jlk6a8WmY2VVWxYrf0U4lHZ4C7/GFxHuXRfzlmElwmt93EYR7t7vmL6npwi7OCxhdfFdhF9Ae3pazrTpmJZnNULLmZ3Le4XHTUXuWGbS/BbaVOxfl+N62Np39TymOEZb+y9gSGUzX47a5uxO8wFNW2fS3Fo6a8W2I2XVsZxhPx5z3/p6zB2nsi90zL13bfpZLp9+Ns2Ha2UmzbuN8pfJzyYAAAAA/tcf6SotfLwuezoAAAAASUVORK5CYII=",
      cropImg: "",
      result: "",
    }
  },
  created() {
    //console.log('app-ocr created')
  },
  mounted() {
    //console.log('app-ocr mounted')
    messages.on("newSnapshot", (img) => {
      this.imgSrc = img
      this.$refs.cropper.replace(img)
    })
    
    messages.on("plateOcr", (info) => {
      console.log('车牌识别结果...', info)
    })
    
    messages.on("licenseOcr", (info) => {
      console.log('驾照识别结果', info)
    })
  },
  beforeDestroy() {
    //console.log('app-ocr beforeDestroy')
  },
  methods: {
    doOcr(mode) {
      this.$http.post("/baidu", {
          mode: mode,
          img: this.cropImg
      }).then( res => {
        console.log(res)
        this.result = JSON.stringify(res)
      }).catch( err => {
        console.log(err)
        this.result = JSON.stringify(err)
      })
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      if(this.cropImg === 'data:,') {
        this.cropImg = ''
      }
    },
  },
}
</script>
